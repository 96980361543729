import Autoplay from "embla-carousel-autoplay"
import { Avatar, AvatarImage, AvatarFallback } from "app/components/ui/avatar"
import { CarouselContent, CarouselItem, Carousel, CarouselNext, CarouselPrevious } from "app/components/ui/carousel"
import type { Testimonial } from "app/core/interfaces/testimonial"
import { Suspense, type ComponentPropsWithoutRef } from "react"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import BoringAvatar from "boring-avatars"

interface Props extends ComponentPropsWithoutRef<"div"> {
  reviews: Testimonial[]
}
export function TestimonialCarousel({ reviews, children }: Props) {
  if (!reviews || reviews.length === 0) return null
  return (
    <Suspense>
      <Carousel
        opts={{ loop: true }}
        plugins={[
          Autoplay({
            delay: 2000,
          }),
        ]}
      >
        <CarouselContent>
          {[...reviews, ...reviews, ...reviews].map((review, index) => (
            <Dialog key={index}>
              <DialogTrigger asChild>
                <CarouselItem className=" basis-4/5 xs:basis-2/3 sm:basis-1/2 md:basis-1/3 lg:basis-1/4">
                  <div className="bg-white aspect-square p-4 flex-1 flex flex-col items-start justify-between">
                    <div>
                      <p className="text-md line-clamp-[7] md:line-clamp-6 text-background/80">{review.data.message}</p>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      <Avatar>
                        <AvatarImage src={review.data.avatar?.url!} alt={review.data.name} />
                        <AvatarFallback>
                          <BoringAvatar name={review.data.name ?? ""} />
                        </AvatarFallback>
                      </Avatar>
                      <div className="flex flex-col justify-center items-start space-y-1">
                        <span className="text-sm text-background/50 font-display leading-none">{review.data.name}</span>
                        {review.data.rating && (
                          <span className="flex flex-row gap-[2px] text-xs">
                            {Array.from({ length: review.data.rating }).map((_, i) => (
                              <span key={i}>{children}</span>
                            ))}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </CarouselItem>
              </DialogTrigger>
              <DialogContent className="light border-transparent !rounded-none">
                <DialogHeader>
                  <DialogTitle className="w-full flex flex-row items-center justify-start gap-2">
                    <Avatar>
                      <AvatarImage src={review.data.avatar?.url!} alt={review.data.name} />
                      <AvatarFallback>
                        <BoringAvatar name={review.data.name ?? ""} />
                      </AvatarFallback>
                    </Avatar>
                    <span className="font-display">{review.data.name}</span>
                  </DialogTitle>
                  <DialogDescription className="space-y-4 pt-2">
                    <p className="text-base text-foreground/80 text-left">{review.data.message}</p>

                    {review.data.original_url && (
                      <div>
                        <a href={review.data.original_url} target="_blank" rel="noreferrer" className="text-sm hover:underline">
                          View original ↗
                        </a>
                      </div>
                    )}

                    <div className="flex flex-col justify-center items-start">
                      {review.data.rating && (
                        <span className="flex flex-row gap-[2px] text-xs">
                          {Array.from({ length: review.data.rating }).map((rating, i) => (
                            <span key={i}>{children}</span>
                          ))}
                        </span>
                      )}
                    </div>
                  </DialogDescription>
                </DialogHeader>
              </DialogContent>
            </Dialog>
          ))}
        </CarouselContent>
        <div className="relative flex flex-row gap-2 mt-4">
          <CarouselPrevious className="z-50 relative translate-y-0 top-0 left-0 bg-transparent border" />
          <CarouselNext className="z-50 relative translate-y-0 top-0 left-0 bg-transparent border" />
        </div>
      </Carousel>
    </Suspense>
  )
}
